@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Alphabetized Cassette Tapes Classic';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/Alphabetized Cassette Tapes Classic.ttf')
      format('truetype');
  }

  @font-face {
    font-family: 'Alphabetized Cassette Tapes Thin';
    font-style: normal;
    font-weight: 300;
    src: url('../assets/Alphabetized Cassette Tapes Thin.ttf')
      format('truetype');
  }

  @font-face {
    font-family: 'Alphabetized Cassette Tapes';
    font-style: normal;
    font-weight: 400;
    src: url('../assets/Alphabetized Cassette Tapes.ttf') format('truetype');
  }
}

.custom-gradient {
  background-image: linear-gradient(
    to right,
    #f0fbb9 0%,
    #fbb3a0 10%,
    #bfa2fd 60%,
    #a4d0fc,
    #9fe9e2 90%,
    #9fface
  );
  opacity: 0.6;
}

.icon:hover {
  filter: invert(0);
}
