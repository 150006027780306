@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

@font-face {
  font-family: Alphabetized Cassette Tapes Classic;
  font-style: normal;
  font-weight: 400;
  src: url("Alphabetized Cassette Tapes Classic.00b17b90.ttf") format("truetype");
}

@font-face {
  font-family: Alphabetized Cassette Tapes Thin;
  font-style: normal;
  font-weight: 300;
  src: url("Alphabetized Cassette Tapes Thin.f7f177ba.ttf") format("truetype");
}

@font-face {
  font-family: Alphabetized Cassette Tapes;
  font-style: normal;
  font-weight: 400;
  src: url("Alphabetized Cassette Tapes.27300d21.ttf") format("truetype");
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.pointer-events-none {
  pointer-events: none;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-inset-3 {
  inset: -.75rem;
}

.-left-12 {
  left: -3rem;
}

.-left-2 {
  left: -.5rem;
}

.-left-\[2\.65rem\] {
  left: -2.65rem;
}

.-left-\[5\.6rem\] {
  left: -5.6rem;
}

.-right-12 {
  right: -3rem;
}

.-right-4 {
  right: -1rem;
}

.-right-\[2\.65rem\] {
  right: -2.65rem;
}

.-top-1 {
  top: -.25rem;
}

.-top-11 {
  top: -2.75rem;
}

.-top-2 {
  top: -.5rem;
}

.-top-20 {
  top: -5rem;
}

.-top-7 {
  top: -1.75rem;
}

.-top-\[3\.15rem\] {
  top: -3.15rem;
}

.bottom-0 {
  bottom: 0;
}

.left-10 {
  left: 2.5rem;
}

.left-16 {
  left: 4rem;
}

.left-20 {
  left: 5rem;
}

.left-4 {
  left: 1rem;
}

.left-\[10\.25rem\] {
  left: 10.25rem;
}

.left-\[25rem\] {
  left: 25rem;
}

.right-0 {
  right: 0;
}

.right-10 {
  right: 2.5rem;
}

.right-12 {
  right: 3rem;
}

.right-16 {
  right: 4rem;
}

.right-20 {
  right: 5rem;
}

.right-24 {
  right: 6rem;
}

.right-3 {
  right: .75rem;
}

.right-36 {
  right: 9rem;
}

.right-4 {
  right: 1rem;
}

.right-6 {
  right: 1.5rem;
}

.right-8 {
  right: 2rem;
}

.top-0 {
  top: 0;
}

.top-10 {
  top: 2.5rem;
}

.top-12 {
  top: 3rem;
}

.top-20 {
  top: 5rem;
}

.top-3 {
  top: .75rem;
}

.top-36 {
  top: 9rem;
}

.top-5 {
  top: 1.25rem;
}

.top-8 {
  top: 2rem;
}

.top-9 {
  top: 2.25rem;
}

.top-\[7\.75rem\] {
  top: 7.75rem;
}

.-z-10 {
  z-index: -10;
}

.z-20 {
  z-index: 20;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.row-span-1 {
  grid-row: span 1 / span 1;
}

.m-8 {
  margin: 2rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-1\.5 {
  margin-left: .375rem;
  margin-right: .375rem;
}

.mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-\[4\.25rem\] {
  margin-bottom: 4.25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-40 {
  margin-top: 10rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-36 {
  height: 9rem;
}

.h-40 {
  height: 10rem;
}

.h-44 {
  height: 11rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-52 {
  height: 13rem;
}

.h-6 {
  height: 1.5rem;
}

.h-64 {
  height: 16rem;
}

.h-7 {
  height: 1.75rem;
}

.h-\[8rem\] {
  height: 8rem;
}

.h-full {
  height: 100%;
}

.max-h-0 {
  max-height: 0;
}

.max-h-\[215px\] {
  max-height: 215px;
}

.max-h-full {
  max-height: 100%;
}

.min-h-\[430px\] {
  min-height: 430px;
}

.w-12 {
  width: 3rem;
}

.w-2 {
  width: .5rem;
}

.w-24 {
  width: 6rem;
}

.w-32 {
  width: 8rem;
}

.w-5 {
  width: 1.25rem;
}

.w-52 {
  width: 13rem;
}

.w-6 {
  width: 1.5rem;
}

.w-60 {
  width: 15rem;
}

.w-7 {
  width: 1.75rem;
}

.w-72 {
  width: 18rem;
}

.w-\[15px\] {
  width: 15px;
}

.w-\[2\.6rem\] {
  width: 2.6rem;
}

.w-\[21\.75rem\] {
  width: 21.75rem;
}

.w-full {
  width: 100%;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-96 {
  max-width: 24rem;
}

.max-w-\[1150px\] {
  max-width: 1150px;
}

.max-w-\[1512px\] {
  max-width: 1512px;
}

.max-w-\[30rem\] {
  max-width: 30rem;
}

.max-w-\[374\.5px\] {
  max-width: 374.5px;
}

.max-w-\[38rem\] {
  max-width: 38rem;
}

.max-w-\[800px\] {
  max-width: 800px;
}

.max-w-full {
  max-width: 100%;
}

.-translate-x-3 {
  --tw-translate-x: -.75rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-\[8px\] {
  --tw-translate-x: -8px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-0 {
  --tw-translate-y: -0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-0\.5 {
  --tw-translate-y: -.125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-2 {
  --tw-translate-y: -.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[1px\] {
  --tw-translate-y: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[2px\] {
  --tw-translate-y: -2px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[7px\] {
  --tw-translate-x: 7px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-\.42 {
  --tw-scale-x: .42;
  --tw-scale-y: .42;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-110 {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-150 {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes blob {
  0% {
    transform: translate(0)scale(1);
  }

  33% {
    transform: translate(10px, -20px)scale(1.1);
  }

  66% {
    transform: translate(-10px, 20px)scale(.9);
  }

  100% {
    transform: tranlate(0px, 0px) scale(1);
  }
}

.animate-blob {
  animation: 7s infinite blob;
}

@keyframes tilt {
  0%, 50%, 100% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(1.75deg);
  }

  75% {
    transform: rotate(-1.75deg);
  }
}

.animate-tilt {
  animation: 10s linear infinite tilt;
}

.cursor-pointer {
  cursor: pointer;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.place-items-center {
  place-items: center;
}

.place-items-baseline {
  place-items: baseline;
}

.content-center {
  align-content: center;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-center {
  justify-items: center;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-\[2px\] {
  gap: 2px;
}

.space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.self-center {
  align-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.text-wrap {
  text-wrap: wrap;
}

.break-normal {
  overflow-wrap: normal;
  word-break: normal;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-\[50\%\] {
  border-radius: 50%;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b-3xl {
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-4 {
  border-width: 4px;
}

.border-8 {
  border-width: 8px;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l-0 {
  border-left-width: 0;
}

.border-r-0 {
  border-right-width: 0;
}

.border-t-0 {
  border-top-width: 0;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-dashed {
  border-style: dashed;
}

.border-\[\#AB96F8\] {
  --tw-border-opacity: 1;
  border-color: rgb(171 150 248 / var(--tw-border-opacity));
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.bg-\[\#44B0FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(68 176 255 / var(--tw-bg-opacity));
}

.bg-\[\#44FF9A\] {
  --tw-bg-opacity: 1;
  background-color: rgb(68 255 154 / var(--tw-bg-opacity));
}

.bg-\[\#8B44FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(139 68 255 / var(--tw-bg-opacity));
}

.bg-\[\#EBFF70\] {
  --tw-bg-opacity: 1;
  background-color: rgb(235 255 112 / var(--tw-bg-opacity));
}

.bg-\[\#FF6644\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 102 68 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-green-300 {
  --tw-gradient-from: #86efac var(--tw-gradient-from-position);
  --tw-gradient-to: #86efac00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-500 {
  --tw-gradient-from: #a855f7 var(--tw-gradient-from-position);
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-indigo-500 {
  --tw-gradient-to: #6366f100 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-purple-500 {
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #a855f7 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.to-pink-400 {
  --tw-gradient-to: #f472b6 var(--tw-gradient-to-position);
}

.to-yellow-200 {
  --tw-gradient-to: #fef08a var(--tw-gradient-to-position);
}

.fill-current {
  fill: currentColor;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-\[27px\] {
  padding: 27px;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-60 {
  padding-bottom: 15rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-2\.5 {
  padding-right: .625rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-2\.5 {
  padding-top: .625rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-alphabetized-cassette-tapes {
  font-family: Alphabetized Cassette Tapes, sans-serif;
}

.font-inter {
  font-family: Inter, sans-serif;
}

.font-mulish {
  font-family: Mulish, sans-serif;
}

.font-open-sans {
  font-family: Open Sans, sans-serif;
}

.font-plus-jakarta-sans {
  font-family: Plus Jakarta Sans, sans-serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[21px\] {
  font-size: 21px;
}

.text-\[22px\] {
  font-size: 22px;
}

.text-\[28px\] {
  font-size: 28px;
}

.text-\[2rem\] {
  font-size: 2rem;
}

.text-\[8px\] {
  font-size: 8px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-black {
  font-weight: 900;
}

.font-blacker {
  font-weight: 950;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-3 {
  line-height: .75rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-\[2\.15rem\] {
  line-height: 2.15rem;
}

.leading-\[2\.75rem\] {
  line-height: 2.75rem;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-tighter {
  letter-spacing: -.05em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.text-\[\#0D0D0D\] {
  --tw-text-opacity: 1;
  color: rgb(13 13 13 / var(--tw-text-opacity));
}

.text-\[\#4F4F4F\] {
  --tw-text-opacity: 1;
  color: rgb(79 79 79 / var(--tw-text-opacity));
}

.text-\[\#98A2B3\] {
  --tw-text-opacity: 1;
  color: rgb(152 162 179 / var(--tw-text-opacity));
}

.text-\[\#F9FAFB\] {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-transparent {
  color: #0000;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.decoration-1 {
  text-decoration-thickness: 1px;
}

.underline-offset-4 {
  text-underline-offset: 4px;
}

.underline-offset-\[5px\] {
  text-underline-offset: 5px;
}

.underline-offset-\[6px\] {
  text-underline-offset: 6px;
}

.opacity-0 {
  opacity: 0;
}

.opacity-50 {
  opacity: .5;
}

.opacity-55 {
  opacity: .55;
}

.opacity-60 {
  opacity: .6;
}

.opacity-75 {
  opacity: .75;
}

.mix-blend-multiply {
  mix-blend-mode: multiply;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-2xl {
  --tw-blur: blur(40px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-1000 {
  transition-duration: 1s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.custom-gradient {
  opacity: .6;
  background-image: linear-gradient(to right, #f0fbb9 0%, #fbb3a0 10%, #bfa2fd 60%, #a4d0fc, #9fe9e2 90%, #9fface);
}

.icon:hover {
  filter: invert(0);
}

.hover\:bg-pink-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(251 207 232 / var(--tw-bg-opacity));
}

.hover\:bg-opacity-90:hover {
  --tw-bg-opacity: .9;
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.hover\:text-indigo-500:hover {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}

.hover\:text-yellow-500:hover {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:text-shadow:hover {
  text-shadow: -.5px .5px purple;
  text-decoration: underline;
  transition-duration: .4s;
}

.group:hover .group-hover\:text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity));
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:duration-200 {
  transition-duration: .2s;
}

.peer:checked ~ .peer-checked\:max-h-\[400px\] {
  max-height: 400px;
}

.peer:checked ~ .peer-checked\:rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:font-bold {
  font-weight: 700;
}

@media (width >= 320px) {
  .xxs\:h-6 {
    height: 1.5rem;
  }

  .xxs\:w-6 {
    width: 1.5rem;
  }

  .xxs\:w-72 {
    width: 18rem;
  }

  .xxs\:scale-\.80 {
    --tw-scale-x: .8;
    --tw-scale-y: .8;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xxs\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .xxs\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xxs\:text-\[2\.2rem\] {
    font-size: 2.2rem;
  }
}

@media (width >= 424px) {
  .xs\:h-8 {
    height: 2rem;
  }

  .xs\:w-72 {
    width: 18rem;
  }

  .xs\:w-8 {
    width: 2rem;
  }

  .xs\:w-80 {
    width: 20rem;
  }

  .xs\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xs\:whitespace-nowrap {
    white-space: nowrap;
  }

  .xs\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .xs\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xs\:text-\[2\.5rem\] {
    font-size: 2.5rem;
  }
}

@media (width >= 768px) {
  .md\:bottom-20 {
    bottom: 5rem;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:gap-3 {
    gap: .75rem;
  }

  .md\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse));
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:tracking-normal {
    letter-spacing: 0;
  }
}

@media (width >= 1024px) {
  .lg\:absolute {
    position: absolute;
  }

  .lg\:-top-2 {
    top: -.5rem;
  }

  .lg\:-top-3 {
    top: -.75rem;
  }

  .lg\:right-32 {
    right: 8rem;
  }

  .lg\:right-\[23rem\] {
    right: 23rem;
  }

  .lg\:top-10 {
    top: 2.5rem;
  }

  .lg\:top-32 {
    top: 8rem;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .lg\:-mt-0 {
    margin-top: 0;
  }

  .lg\:mb-12 {
    margin-bottom: 3rem;
  }

  .lg\:mb-20 {
    margin-bottom: 5rem;
  }

  .lg\:mb-\[34px\] {
    margin-bottom: 34px;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:mt-2 {
    margin-top: .5rem;
  }

  .lg\:mt-2\.5 {
    margin-top: .625rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-12 {
    height: 3rem;
  }

  .lg\:h-60 {
    height: 15rem;
  }

  .lg\:h-\[1\.56rem\] {
    height: 1.56rem;
  }

  .lg\:w-10 {
    width: 2.5rem;
  }

  .lg\:w-\[25rem\] {
    width: 25rem;
  }

  .lg\:min-w-\[50rem\] {
    min-width: 50rem;
  }

  .lg\:min-w-\[54rem\] {
    min-width: 54rem;
  }

  .lg\:max-w-80 {
    max-width: 20rem;
  }

  .lg\:max-w-\[19rem\] {
    max-width: 19rem;
  }

  .lg\:max-w-\[32rem\] {
    max-width: 32rem;
  }

  .lg\:max-w-\[49rem\] {
    max-width: 49rem;
  }

  .lg\:max-w-\[686px\] {
    max-width: 686px;
  }

  .lg\:-translate-x-\[6px\] {
    --tw-translate-x: -6px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-1 {
    --tw-translate-y: -.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-3 {
    --tw-translate-y: -.75rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-\[3px\] {
    --tw-translate-y: -3px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:-translate-y-\[4px\] {
    --tw-translate-y: -4px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:translate-y-1 {
    --tw-translate-y: .25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .lg\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .lg\:items-end {
    align-items: flex-end;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-items-center {
    justify-items: center;
  }

  .lg\:gap-20 {
    gap: 5rem;
  }

  .lg\:gap-6 {
    gap: 1.5rem;
  }

  .lg\:gap-\[2px\] {
    gap: 2px;
  }

  .lg\:border-b-0 {
    border-bottom-width: 0;
  }

  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .lg\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:text-\[2\.5rem\] {
    font-size: 2.5rem;
  }

  .lg\:text-\[28px\] {
    font-size: 28px;
  }

  .lg\:text-\[2rem\] {
    font-size: 2rem;
  }

  .lg\:text-\[40px\] {
    font-size: 40px;
  }

  .lg\:text-\[58px\] {
    font-size: 58px;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:leading-10 {
    line-height: 2.5rem;
  }

  .lg\:leading-\[2\.15rem\] {
    line-height: 2.15rem;
  }

  .lg\:leading-\[2\.75rem\] {
    line-height: 2.75rem;
  }

  .lg\:leading-tight {
    line-height: 1.25;
  }

  .lg\:decoration-2 {
    text-decoration-thickness: 2px;
  }

  .lg\:underline-offset-8 {
    text-underline-offset: 8px;
  }
}

@media (width >= 1280px) {
  .xl\:max-w-\[1136px\] {
    max-width: 1136px;
  }

  .xl\:max-w-\[1250px\] {
    max-width: 1250px;
  }

  .xl\:max-w-screen-2xl {
    max-width: 1536px;
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:gap-0 {
    gap: 0;
  }

  .xl\:px-\[7\.25rem\] {
    padding-left: 7.25rem;
    padding-right: 7.25rem;
  }

  .xl\:pt-12 {
    padding-top: 3rem;
  }
}

@media (width >= 1350px) {
  .xxl\:-right-\[12rem\] {
    right: -12rem;
  }

  .xxl\:-top-\[10rem\] {
    top: -10rem;
  }

  .xxl\:flex {
    display: flex;
  }
}
/*# sourceMappingURL=index.3375ecd0.css.map */
